.about {
    margin: 8rem auto 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.about img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about_text {
    width: calc(var(--max-width) / 2);
    margin-left: auto;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.about_text h1 {
    max-width: 16rem;
    font-size: 2rem;
    font-weight: 600;
}

.about_text p {
    font-size: 1.4rem;
    font-weight: 500;
    max-width: 29rem;
}

.about_text small {
    border: 2px solid var(--accent-alt-color);
    width: 15.5rem;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 600;
    display: grid;
    place-items: center;
    padding: 0.7rem;
    border-radius: 100vw;
}

.polosa {
    background-color: var(--accent-alt-color);
}

.polosa_inner {
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.75rem;
    padding-bottom: 2rem;
    color: white;
}

.polosa_items {
    margin-block: 5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    width: 100%;
}

.polosa_item {
    display: flex;
    flex-direction: column;
}

.polosa_item span:first-child {
    font-size: 5rem;
    text-align: center;
    border-bottom: 1px solid white;
    display: block;
    height: 100%;
    padding: 1rem;
}
.polosa_item span:first-child small {
    font-size: 1.5rem;
    font-weight: 200;
}
.polosa_item span:last-child {
    text-align: center;
    font-size: 1.5rem;
    display: block;
    height: 100%;
    padding: 1rem;
}

.text {
    font-weight: 600;
    text-align: center;
    margin: 4rem auto;
    max-width: 35rem;
    font-size: 1.2rem;
    padding: 0.75rem;
}

.imgs {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
}
.imgs img {
    width: 100%;
}
.feedback {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.feedback h2 {
    font-size: 2rem;
    font-weight: 600;
}

.fb_info {
    width: 100%;
    max-width: calc(var(--max-width) / 2);
    margin-left: auto;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-block: 2rem;
}

.fb_info li {
    font-size: 1.2rem;
    font-weight: 600;
}

.fb_form_wrapper {
    background-color: var(--accent-alt-color);
}

.fb_form {
    width: 100%;
    max-width: calc(var(--max-width) / 2);
    margin-right: auto;
    padding: 0.75rem;
    height: 100%;
    padding-block: 2rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        'name comment'
        'phone comment'
        'cb none'
        'submit none';
    gap: 2rem;
}
.inputs a {
    text-decoration: underline;
    color: inherit;
}
.inputs button {
    background-color: black;
    border: none;
    border-radius: 100vw;
    color: white;
    padding: 1rem;
    text-align: center;
    font-size: 0.8rem;
    cursor: pointer;
    grid-area: submit;
}
.inputs button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.inputs input,
.inputs textarea {
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;
    font-size: 0.8rem;
    resize: none;
    border: none;
    width: 100%;
    outline: none;
}

.inputs input[name='name'] {
    grid-area: name;
    border-radius: 1.5rem;
}
.inputs input[name='phone'] {
    grid-area: phone;
    border-radius: 1.5rem;
}
.inputs textarea[name='comment'] {
    grid-area: comment;
}

.inputs label {
    grid-area: cb;
    font-size: 0.7rem;
    font-weight: 400;
    position: relative;
    padding-left: 4rem;
    max-width: 15rem;
    cursor: pointer;
}

.inputs label::before {
    content: '';
    width: 1.2rem;
    aspect-ratio: 1;
    border-radius: 100vw;
    background-color: white;
    position: absolute;
    top: 0;
    left: 2rem;
}
.inputs label:has(input:checked)::after {
    content: '';
    width: 0.8rem;
    aspect-ratio: 1;
    border-radius: 100vw;
    background-color: var(--accent-alt-color);
    position: absolute;
    top: 0.2rem;
    left: 2.2rem;
}
@media (max-width: 1000px) {
    .about {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 4rem;
    }
    .about img {
        aspect-ratio: 16 / 4;
    }

    .about_text {
        width: 100%;
        margin: 0;
    }
    .about_text small {
        margin-inline: auto;
    }
}

@media (max-width: 1200px) {
    .inputs {
        grid-template-columns: 1fr;
        grid-template-areas:
            'name'
            'phone'
            'comment'
            'cb'
            'submit';
    }
}

@media (max-width: 800px) {
    .polosa_items {
        grid-template-columns: 1fr 1fr;
    }
    .feedback {
        grid-template-columns: 1fr;
    }
    .fb_info,
    .fb_form {
        margin: 0;
    }

    .feedback h2 {
        font-size: 1.2rem;
        text-wrap: balance;
    }
    .fb_info li {
        font-weight: 400;
    }
}

@media (max-width: 420px) {
    .polosa_items {
        grid-template-columns: 1fr;
    }
}
