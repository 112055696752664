.footer, .preFooter {
    width: 100%;
    padding: 2rem;
    background-color: var(--text-color);
    display: flex;
    flex: 0 0 auto;
    /* z-index: 999; */
}

.preFooter {
    background-color: var(--white-color);
    border-top: 2px solid var(--grey-color);
}
.footerMinicart {
    padding: 96px;
}
.inner {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0;
    display: grid;
}

.preFooter .inner {
    color: var(--text-color);
    grid-template-areas:
            "left"
            "center"
            "right";
    grid-template-rows: repeat(3, 1fr);
    place-items: center;
    padding: 2rem 0;
}

.footer .inner {
    color: var(--white-color);
    grid-template-areas:
                "social"
                "mobileApps"
                "paymentTypes"
                "copyright";
}

.left {
    grid-area: left;
    list-style: none;
    padding: 0;
    text-align: center;
}

.left a {
    text-decoration: none;
    font-weight: bold;
    color: var(--text-color);
}

.center {
    grid-area: center;
    justify-self: center;
}

.center img {
    width: 5rem;
    height: auto;
}

.right {
    grid-area: right;
    display: flex;
    flex-direction: column;
    font-size: .75rem;
}

.right h5 {
    padding: 0;
    font-size: 1rem;
    margin: 0 0 0.25rem;
}

.right a {
    text-decoration: none;
    font-weight: bold;
    color: var(--text-color);
}

.wa {
    display: flex;
    align-items: center;
}

.social {
    display: flex;
    grid-area: social;
    justify-self: center;
}

.social > a img {
    width: auto;
    height: 2rem;
}

.social > a:not(:last-child) {
    margin-right: .75rem;
}

.paymentTypes {
    grid-area: paymentTypes;
    justify-self: center;
    display: flex;
    align-items: center;
}

.paymentTypes img {
    height: 1.25rem;
    width: auto;
    max-width: 100%;
}

.paymentTypes img:not(:last-child) {
    margin-right: .5rem;
}

.copyright {
    margin-top: 2rem;
    grid-area: copyright;
    justify-self: center;
    align-self: end;
}

.logoBg {
    height: 2.5rem;
    width: 2.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 0;
}

@media (min-width: 768px) {
    .paymentTypes {
        justify-self: end;
    }

    .preFooter .inner {
        grid-template-areas: "left center right";
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
    }

    .left {
        justify-self: end;
        text-align: right;
    }

    .footer .inner {
        grid-template-areas:
                "social mobileApps paymentTypes"
                "copyright copyright copyright";
    }

    .social {
        justify-self: start;
    }
}