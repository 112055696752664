@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');
@font-face {
    font-family: 'Stem';
    src: url('assets/fonts/STM45.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('assets/fonts/STM65.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Stem';
    src: url('assets/fonts/STM75.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Benzin';
    src: url('assets/fonts/Benzin-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Benzin';
    src: url('assets/fonts/Benzin-Semibold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
    font-family: 'Onest', sans-serif;
}

.wf-active body,
.wf-active button,
.wf-active input,
.wf-active textarea {
    font-family: 'Stem', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

:root {
    --white-color: rgb(250, 250, 250);
    --text-color: rgb(28, 28, 28);
    --accent-color: rgb(218, 255, 2);
    /* --accent-alt-color: rgb(178, 88, 241); */
    --accent-alt-color: #87d1fe;
    --dark-green-color: rgb(40, 60, 27);
    --grey-color: rgb(210, 215, 220);
    --dark-color: rgb(126, 126, 126);
    --light-blue-color: #87cffd;
    --max-width: 75rem;
    --benzin-font: 'Benzin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0 auto;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100vh;
    color: var(--text-color);
    font-weight: normal;
    background-color: var(--white-color);
}

#root {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
}

.d-none {
    display: none !important;
}

.mt-1 {
    margin-top: 1rem;
}

.input {
    width: 100%;
    font-size: 1rem;
    height: 40px;
    padding: 0;
    line-height: 1.496;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid var(--grey-color);
    background: 0 0;
    font-weight: bold;
}

.input:focus {
    outline: none;
    border-bottom: 1px solid var(--text-color);
}

.btn,
.btn--small {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: var(--accent-alt-color);
    border: 1px solid var(--accent-alt-color);
    outline: none;
    cursor: pointer;
    color: white;
    transition: all 0.25s ease-in;
    text-decoration: none;
    display: block;
    text-align: center;
    opacity: 1;
    position: relative;
}

.btn--small {
    padding: 0.25rem 1rem;
    font-size: 0.875rem;
    background-color: var(--light-blue-color);
    border: 1px solid var(--light-blue-color);
}

.btn:hover {
    background-color: transparent;
    color: var(--accent-alt-color);
}

.btn--small:hover {
    background-color: transparent;
    color: var(--text-color);
}

.btn:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.btnLoading {
    background-color: transparent;
}

.btnLoading:disabled {
    opacity: 1 !important;
}

.btnLoading span {
    visibility: hidden;
    opacity: 0;
}

.btnLoading::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: var(--accent-alt-color);
    border-radius: 50%;
    animation: LoadingSpinner 1s ease infinite;
}

.btn-uppercase {
    text-transform: uppercase;
    font-size: 0.875rem;
}

.btn-login {
    width: auto;
    font-size: 0.875rem;
    padding: 0.25rem 0.75rem;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: var(--text-color);
}

.btn-login:hover {
    color: var(--text-color);
}

.groupTag {
    display: flex;
    align-items: center;
    height: 2.5rem;
}

.swiper-container {
    overflow-x: hidden !important;
    overflow-y: hidden;
}

.hamburger {
    padding: 0 !important;
}

.hamburger-box {
    margin-top: 0.25rem;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    background-color: black !important;
}

.error {
    color: tomato !important;
}

.success {
    color: green;
}

.hidden {
    display: none;
}

.form__input {
    display: block;
    position: relative;
}

.form__input input.success {
    border: 1px solid rgba(90, 169, 0, 0.5);
    background-color: rgba(90, 169, 0, 0.1);
}

.form__input input:disabled {
    cursor: not-allowed;
}

.form__input:not(:last-child) {
    margin-bottom: 1rem;
}

.form__input label {
    position: absolute;
    cursor: text;
    font-size: 80%;
    opacity: 1;
    top: -0.2rem;
    left: 0.75rem;
    z-index: 3;
    line-height: 1;
    padding: 0 1px;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.form__input label:after {
    content: ' ';
    display: block;
    position: absolute;
    height: 5px;
    top: 3px;
    left: -0.2em;
    right: -0.2em;
    z-index: -1;
    background: white !important;
}

.form__input input {
    border-radius: 0.1rem;
    outline: medium none invert !important;
    outline: initial !important;
    box-shadow: none !important;
    font-size: 1em;
    border: 1px solid var(--grey-color);
    background: white;
    color: var(--text-color);
    height: auto;
    padding: 0.5rem 0.75rem;
    line-height: 1;
    display: block;
    width: 100%;
    font-weight: 300;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0;
}

.form__input .error {
    background: #fff0f4;
    color: rgb(197, 18, 68);
    border: 1px solid rgb(197, 18, 68) !important;
}

.form__input .success {
    background: rgb(238, 255, 239);
    color: rgb(64, 129, 64);
    border: 1px solid rgb(83, 166, 83) !important;
}

.form__input--radios {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.form__radio {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 0.875em;
}

.form__radio:not(:last-child) {
    margin-right: 1rem;
}

.form__checkbox {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
}

.form__checkbox label {
    margin: 0;
    padding: 0;
    cursor: pointer;
    width: 200px;
}

.form__radio label {
    margin-top: -3px;
    cursor: pointer;
}

[type='radio']:checked,
[type='radio']:not(:checked),
[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 1.5rem;
    cursor: pointer;
    display: inline-flex;
    width: 100%;
    color: var(--text-color);
}

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--grey-color);
    border-radius: 100%;
    background: white;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--accent-alt-color);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type='radio']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

[type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
}

.form__notification {
    text-align: center;
    font-weight: bold;
}

input[type='checkbox'].checkbox {
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
}

input[type='checkbox'].checkbox + label {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    /*margin-left: -.75rem;*/
}

label {
    margin-top: -0.25rem;
}

input[type='checkbox'].checkbox + label:hover {
    color: var(--accent-alt-color);
    font-size: 1rem;
}

input[type='checkbox'].checkbox + label::before {
    transition: all 0.3s;
    content: '';
    display: inline-flex;
    /*margin-top: .5rem;*/
    width: 1em;
    height: 1em;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-right: 0.5rem;
}

input[type='checkbox'].checkbox:checked + label::before {
    transition: all 0.3s;
    border-color: var(--accent-alt-color);
    background-color: var(--accent-alt-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    box-shadow: 2px 2px 6px 0 var(--accent-alt-color);
}

.btn--sup {
    width: auto;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: var(--grey-color);
    border: 1px solid var(--grey-color);
    outline: none;
    cursor: pointer;
    color: var(--text-color);
    transition: all 0.25s ease-in;
    text-decoration: none;
    display: block;
    text-align: center;
    opacity: 1;
}

.btn--sup:hover {
    background-color: white;
}

/* Select */
.select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-size: 1rem;
    color: var(--text-color);
    border: 1px solid var(--grey-color);
    min-width: 18rem;
}
.select-box__current {
    position: relative;
    cursor: pointer;
    outline: none;
}
.select-box__current:focus + .select-box__list {
    opacity: 1;
    -webkit-animation-name: none;
    animation-name: none;
    box-shadow: 0 4px 15px rgba(115, 46, 163, 0.4);
}
.select-box__current:focus + .select-box__list .select-box__option {
    cursor: pointer;
}
.select-box__current:focus .select-box__icon {
    -webkit-transform: translateY(-50%) rotate(-90deg);
    transform: translateY(-50%) rotate(-90deg);
}
.select-box__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    -webkit-transform: translateY(-50%) rotate(90deg);
    transform: translateY(-50%) rotate(90deg);
    width: 20px;
    opacity: 0.3;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease;
}
.select-box__value {
    display: -webkit-box;
    display: flex;
}
.select-box__input {
    display: none;
}
.select-box__input:checked + .select-box__input-text {
    display: block;
    text-align: left;
    padding-right: 3rem;
}
.select-box__input-text {
    display: none;
    width: 100%;
    margin: 2px 0 0;
    padding: 0.5rem 0.5rem 0.33rem;
    background-color: #fff;
}
.select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    z-index: 99;
    -webkit-animation-name: HideList;
    animation-name: HideList;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
    animation-timing-function: step-start;
    box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}
.select-box__option {
    display: block;
    padding: 0.5rem;
    background-color: #fff;
}
.select-box__option:hover,
.select-box__option:focus {
    color: var(--accent-alt-color);
    background-color: #fbfbfb;
}

.lds-ring {
    display: none;
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    margin: 8px;
    border: 6px solid #86d0ff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #86d0ff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

sup {
    color: darkred;
}

.error {
    color: darkred;
    font-size: 1rem !important;
    font-weight: normal !important;
    /* display: none; */
}

.error.active {
    display: block;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes HideList {
    from {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}

@keyframes HideList {
    from {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
    to {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
}

@keyframes LoadingSpinner {
    from {
        transform: rotate(0turn);
    }
    to {
        transform: rotate(1turn);
    }
}

@media (min-width: 44rem) {
    .form__radio {
        font-size: 1em;
    }
}

.WAwidget {
    width: 65px;
    height: 65px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(38, 211, 103);
    left: calc(100% - 26px - 70px);
    top: calc(100% - 20px - 70px);
    transition: transform 1s;
    z-index: 9999;
    cursor: pointer;
}
.WAwidget > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 1s;
}
.Widget-info {
    position: absolute;
    right: 130%;
    top: 50%;
    transform: translateY(-50%);

    width: 200px;
    display: none;
    padding: 0;

    padding: 0.6em 1em;
    font-size: 0.8em;
    background-color: rgba(0, 0, 0, 0.733);
    color: white;
}
.Widget-info.--show {
    display: flex;
}
.Widget-info::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 0px;
    top: 50%;
    transform: translateY(-50%);
    right: -30px;
    border: 10px solid transparent;
    border-left: 10px solid rgba(0, 0, 0, 0.733);
}

.WAwidget.--cart {
    top: calc(100% - 100px - 70px);
}
.WAwidget > img:hover {
    transform: scale(1.2);
}

.PWPreviewWidgetButtonIcon {
    all: unset;
    position: relative;
    z-index: 1;
    display: block;
    max-width: 68%;
    aspect-ratio: 1 / 1;
}
@media (max-width: 800px) {
    .Widget-info.--show {
        display: flex;
        width: 200px;
    }
}
