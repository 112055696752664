.StoriesContainer {
    position: relative;
    width: 100%;
    height: 100%;
}
.StoriesContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(180deg,rgba(0,0,0,.5),rgba(0,0,0,.12) 35%,transparent 75%);
    
    z-index: 2;
}

.StoryDurations {
    display: flex;
    flex-direction: row;
    gap: 2px;
    height: 2px;
    position: absolute;
    top: 5px;
    left: .5rem;
    right: .5rem;
    z-index: 2;
}
.StoryDurationItem {
    width: 100%;
    background-color: #6a6a6a;
    border-radius: 2px;
    height: 100%;
    position: relative;
    
}
.StoryDurationItem>div {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: none;
    width: calc(var(--width) * 100%);
    background-color: #eae8e8;
    transition: all 250ms linear;
}
.Active, .Passed {
    display: block !important;
}

.NavigationPrev {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    z-index: 2;
}

.NavigationNext {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 50%;
    z-index: 2;
}


.StoryImg, .StoryVideo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.StoryInfo {
    position: absolute;
    top: 1rem;
    left: .5rem;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    z-index: 4;
    user-select: none;
}

.StoryInfo>img {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
}

.StoryInfo>span {
    color: white;
}

.StoryInfo>span:first-of-type {
    font-weight: bold;
}

.Action {
    position: absolute;
    bottom: 5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    cursor: pointer;
    padding: .6rem 1.2rem;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
}

.VideoSound {
    position: absolute;
    left: 1rem;
    top: 4rem;
    z-index: 5;
    width: 2rem;
    height: auto;
}

.StoryError {
    color: white;
    text-align: center;
}


.StoryVideo::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
}
.StoryVideo::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
}
.StoryVideo::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
}