.header {
    width: 100%;
    padding: .75rem;
    flex: 0 0 auto;
    border-bottom: 1px solid rgb(230, 230, 230);
    position: fixed;
    height: 4rem;
    display: flex;
    flex-direction: column;
    transition: all .2s;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.495, 0.015);
    background-color: var(--white-color);
    z-index: 1003;
}

.header.transparent {
    background-color: transparent;
}

.header:hover {
    background-color: var(--white-color);
}

.header.tiny {
    height: 4rem;
    background-color: var(--white-color);
}

.header.tinyWithLinks {
    height: 4rem;
}

.inner {
    display: grid;
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0;
    grid-template-areas:
        "menu logo nav";
    padding: 0;
    place-content: center;
}

.innerLinks {
    display: none;
    justify-content: center;
    width: 100%;
    max-width: var(--max-width);
    margin: 1rem auto 0;
}

.innerLinks > a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: bold;
    transition: color .4s;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.495, 0.015);
}

.header.transparent .innerLinks > a {
    color: var(--white-color);
}

.header.tiny .innerLinks {
    display: none;
}

.header.tinyWithLinks .innerLinks {
    display: none;
}

.header:hover .innerLinks > a, .header.tiny .innerLinks > a {
    color: var(--text-color);
}

.innerLinks > a:not(:last-child) {
    margin-right: 1rem;
}

.header__button {
    padding: 0;
    background: transparent;
    border: none;
    margin-right: 1rem;
    cursor: pointer;
}

.header__button img {
    height: 1rem;
    width: auto;
}

.header .logoBg {
    height: 2.5rem;
    width: 2.5rem;
    background-size: cover;
    background-repeat: no-repeat;
}

.header.transparent .logoBg {
    background-position-x: 100%;
}

.header:hover .logoBg, .header.tiny .logoBg {
    background-position-x: 0;
}
.header.white {
    background-color: var(--white-color);
}
.header.white .innerLinks > a, .header.tiny .innerLinks > a {
    color: var(--text-color);
}
.header.white .logoBg, .header.tiny .logoBg {
    background-position-x: 0;
}
.menu {
    grid-area: menu;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.phone img {
    height: 1.75rem;
    width: auto;
    margin-left: 1rem;
    margin-top: .25rem;
}

.phoneBtn {
    background-color: var(--text-color);
    display: block;
    color: var(--white-color)!important;
    padding: .5rem 0;
    text-align: center;
    margin-top: 2rem;
    margin-right: 2rem;
}

.logo {
    display: flex;
    justify-content: center;
    grid-area: logo;
    width: 100%;
}

.nav {
    grid-area: nav;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.nav button {
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 0;
}

.nav img {
    height: 1.5rem;
    width: auto;
}

.nav > *:not(:last-child) {
    margin-right: 1.5rem;
}

@media (min-width: 768px) {
    .header {
        height: 7rem;
    }

    .header.tinyWithLinks {
        height: 7rem;
    }

    .innerLinks {
        display: flex;
    }

    .header.tinyWithLinks .innerLinks {
        display: flex;
    }
}