.banner {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 33.3vh;
    background-size: cover;
    background-position: bottom center;
    background-image: var(--background);
}

.alphabet {
    display: none;
    justify-content: space-between;
}

.alphabet span {
    cursor: pointer;
    font-weight: bold;
}

.alphabet span:hover {
    color: var(--accent-alt-color);
}

.brandContent {
    margin-bottom: 1rem;
    display: grid;
    grid-template-areas:
        "letter"
        "product";
    gap: 0;
    padding: 0;
    width: 100%;
    max-width: 30rem;
    position: relative;
}

.brandContent h2 {
    grid-area: letter;
}

.brandContent p {
    padding: 0;
    margin: .25rem 0;
    line-height: 1;
    width: 100%;
}
.brandAnchor {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: -6em;
}

.catalog {
    display: grid;
    width: 100%;
    grid-template-areas:
            "gender"
            "nav"
            "items";
    gap: 0;
    padding: 0;
}

.catalog.catalogSidebar {
    grid-template-areas:
            "sidebar";
    grid-template-columns: 100%;
}

.catalog.catalogSidebar [data-block="items"], .catalog.catalogSidebar [data-block="filter"] {
    display: none;
}

.catalogBrands {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 0;
}

.title {
    justify-self: end;
}

.title p, .title h1 {
    max-width: 15rem;
    text-align: right;
}

.brandLink {
    text-decoration: none;
    color: var(--text-color);
}

.brandLink:hover {
    text-decoration: underline;
}


@media (min-width: 768px) {
    .catalog {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 5fr;
        grid-template-rows: 1fr;
        gap: 2rem;
        grid-template-areas:
                "gender gender"
                "sidebar items";
        padding: 0;
        margin: 0 -2rem 2rem;
    }

    .alphabet {
        display: flex;
    }

    .brandContent {
        margin-bottom: 3rem;
        grid-template-areas:
        "letter letter"
        "product product";
    }

    .brandContent p {
        width: 15rem;
    }
}