.body {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.center {
    text-align: center;
}
.wa {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: rgb(38, 211, 103);
    cursor: pointer;
    margin-top: 2em;
}
.wa__icon {
    width: 100%;
    max-width: 70%;
    z-index: 1;
    aspect-ratio: 1/1;
    display: block;
    /* all: unset; */
    position: relative;
}