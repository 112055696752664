.sidebar {
    grid-area: sidebar;
    display: none;
}

.sidebar.open {
    display: block;
    width: 100%;
}

.filters {
    z-index: 999;
    background: var(--white-color);
    width: 100vw;
    position: fixed;
    top: 4rem;
    bottom: 0;
    opacity: 0;
    right: 0;
    transform: translateX(100vw);
    transition: transform 1s, opacity 1s;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.filtersIsActive {
    transform: translateX(0);
    opacity: 1;
}

.filters nav {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 2rem;
}

.filters nav ul {
    list-style: none;
    padding: 0;
}

.filters nav ul > li {
    font-size: 1.125rem;
    margin-bottom: .5rem;
    font-weight: bold;
}

.filtersButton {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
}

.filtersButton > button {
    margin-bottom: .5rem;
}

.sidebarMobile {
    background-color: var(--grey-color);
    width: 100%;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    grid-area: nav;
}

.filterBtn {
    font-size: 1rem;
    border: none;
    outline: none;
    background-color: white;
    letter-spacing: .115rem;
    padding: .15rem .75rem;
    cursor: pointer;
}

.group:not(:last-child) {
    margin-bottom: 1rem;
}

.applyBtn, .resetBtn {
    width: 100%;
    padding: .75rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: var(--accent-alt-color);
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
}

.resetBtn {
    background-color: var(--dark-color);
}

.groupHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-height: 2.25rem;
    height: auto;
}

.group .groupHead img {
    width: auto;
    height: 1rem;
    transform: rotate(-90deg);
    transition: transform .25s ease-in;
}

.group.closed .groupHead img {
    width: auto;
    height: 1rem;
    transition: transform .25s ease-in;
    transform: rotate(90deg);
}

.group.closed .groupTags {
    display: none;
}

.groupTags {
    padding: .5em 0 0 0;
    margin: 0;
    display: block;
}
.groupTags>input {
    margin-bottom: 1em;
}

.groupTags > .groupTag:not(:last-child) {
    margin-bottom: .25rem;
}

.groupTag {
    display: flex;
    align-items: center;
}
.selectBox {
    border: 0;
    min-width: 15rem;
    margin-bottom: 2em;
}
.selectBox p {
    background-color: inherit;
}

.GenderMobile {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-bottom: 1rem;
    overflow-x: auto;
    scrollbar-width: 0;
}
.GenderMobile::-webkit-scrollbar {
    display: none;
}
.GenderMobile>button {
    display: flex;
    flex-direction: row;
    padding: .7rem;
    padding-left: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.232);
    border-radius: 10rem;
    font-size: .8rem;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    background-color: white;
    color: black;
    flex-shrink: 0;
}
.GenderMobile>button:hover {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.192);
}

.GenderMobile img {
    width: 4rem;
    /* height: /2rem; */
    position: absolute;
    top: -3px;
    left: -1rem;
}
@media (min-width: 768px) {
    .sidebarMobile {
        display: none;
    }
    .sidebar {
        display: block;
    }
    .GenderMobile {
        /* display: none; */
        grid-area: gender;
        margin: 0;
    }
}