.Slider {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    overflow: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.Slider::-webkit-scrollbar {
    display: none;
}
.SliderInner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
}
.SliderItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    max-width: min-content;
}
.SliderItemCircle {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid rgb(238, 189, 227);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Viewed {
    border: 0;
}
.SliderItem>span {
    font-size: .7rem;
    text-align: center;
}
.SliderItemCircle>img {
    width: 95%;
    height: 95%;
    object-fit: cover;
    border-radius: 50%;
}


.StoryWrapper {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
}

.Close::after, .Close::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    top: 50%;
    left: 0;
    background-color: var(--grey-color);
}

.Close::after {
    transform: translateY(-50%) rotate(45deg) scaleX(2);
}

.Close::before {
    transform: translateY(-50%) rotate(-45deg) scaleX(2);
}

.SlideWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 432px;
    /* height: 768px; */
    aspect-ratio: 9 / 16;
}
.Swiper {
    width: 100%;
}


@media screen and (max-height: 767px) {
    .SlideWrapper {
        /* width: 50%; */
        width: auto;
        height: 90vh;
    }
}

@media screen and (max-width: 566px) {
   
    .SlideWrapper {
        width: 100vw;
        height: 100vh;
    }
}
