.notFound {
    margin: 10rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    grid-template-areas: "empty content";
    padding: 0;
    height: 100%;
    align-items: center;
}

.empty {
    grid-area: empty;
}

.content {
    grid-area: content;
}

.notFound a {
    text-decoration: none;
    font-weight: bold;
    color: var(--accent-alt-color);
}

.notFound a:hover {
    text-decoration: underline;
}