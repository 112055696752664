.login {
    margin: 10rem auto 4rem;
    max-width: 22rem;
    width: 100%;
    height: 100%;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    gap: 0.5em;
}

.login a {
    color: inherit;
    text-decoration: none;
}
