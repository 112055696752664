.slider {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 80vh;
    cursor: default;
    overflow: hidden;
    position: relative;
}

.slides {
    height: 100%;
}

.slides.mounted {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.75, 0.05, 0.795, 0.035);
    transition-duration: 1s;
}

.sliderCursorLeft {
    cursor: url("../../assets/images/cursor-left.png"), auto;
}

.sliderCursorRight {
    cursor: url("../../assets/images/cursor-right.png"), auto;
}

.dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 9;
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0;
    margin: 0 0 .5rem .2rem;
}

.dot {
    background-color: var(--accent-color);
    margin: 0 .25rem;
    height: .75em;
    width: .75rem;
    border-radius: .375rem;
    transition-property: width;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.495, 0.015);
    transition-duration: .75s;
    cursor: pointer;
}

.dots li.active {
    width: 2.25rem;
}

.slide {
    background-size: cover;
    height: 100%;
    padding: 0;
    margin: 0 auto;
    background-position: bottom left;
    position: relative;
    float: left;
}
.slide>video, .slide>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}
.slide .content {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 6rem;
    left: 1rem;
    bottom: 0;
}

.content h2 {
    font-weight: bold;
}

.content p {
    font-weight: 400;
}

.content h2, .content p {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    font-family: var(--benzin-font);
}

.content p {
    /* font-size: 1.25rem; */
    font-size: .9rem;
}

.content p {
    margin-bottom: 1.5rem;
}

.content .aBtn {
    padding: .5rem 1rem;
    text-decoration: none;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    transition: background-color .25s ease-in;
    font-weight: bold;
    font-size: 1rem;
    bottom: 1rem;
    /* position: absolute; */
}

.content .aBtn:hover {
    color: white;
}

.content h2 {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.55, 0.05, 0.495, 0.015);
    transition-duration: .75s;
}

@media (min-width: 768px) {
    .slide .content {
        top: 70%;
        transform: translateY(-66%);
        left: 50%;
    }

    .content h2 {
        font-size: 2rem;
    }
    .content p {
        font-size: 1.3rem;
        max-width: 600px;
    }
    .content .aBtn {
        font-size: 1.25rem;
    }

}