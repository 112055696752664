.itemsGroup {
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 33% auto auto;
    gap: 0;
    grid-template-areas: "empty title arrows";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.arrows {
    grid-area: arrows;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.arrowNext, .arrowPrev {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    -webkit-appearance: button;
}

.arrowPrev {
    margin-right: 1rem;
}

.arrowNext img, .arrowPrev img {
    width: 20px;
    height: auto;
}

.arrowNext img {
    transform: rotate(180deg);
}

.events {
    width: 100%;
}

.link {
    text-decoration: none;
    color: var(--text-color);
}

.event {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    height: fit-content;
    width: inherit;
}

.event:not(:last-child) {
    /* margin-right: 1rem !important; */
}

.event img {
    width: auto;
    height: 100%;
    object-fit: contain;
}

.eventTitle {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    align-items: center;
    margin-top: .5rem;
}

.eventTitle h3 {
    margin: 0;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1;
}

.eventTitle time {
    font-size: 1.25rem;
    font-weight: bold;
}

@media (min-width: 768px) {
    .header {
        display: grid;
        margin: 0 auto;
        grid-template-columns: 50% auto 100px;
        gap: 0;
        grid-template-areas: "empty title arrows";
        padding: 0;
    }

    .header h2 {
        font-size: 2rem;
    }

    .event:not(:last-child) {
        margin-right: 2rem !important;
    }

    .eventTitle {
        top: 26rem;
    }

    .eventTitle h3 {
        font-size: 2rem;
    }
}