.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    grid-template-areas: "empty title";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}