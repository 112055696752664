.documents {
    margin: 10rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 50% auto 100px;
    gap: 0;
    grid-template-areas: "empty title arrows";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.content {
    display: grid;
    margin: 0 auto;
    gap: 0;
    padding: 0 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
                "empty"
                "title";
}

.nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 2rem;
    text-align: right;
}

.nav > h3:not(:first-child) {
    margin-top: 0;
}

.nav > h3 {
    margin-bottom: 0;
    cursor: pointer;
}

@media (min-width: 768px) {
    .content {
        grid-template-columns: 50% auto;
        grid-template-areas: "empty title";
    }
    .nav {
        position: fixed;
    }
    .content>div {
        grid-area: title;
    }
}