.cart {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 100%;
    gap: 2rem;
    grid-template-areas:
        "detail"
        "products";
}

.products {
    grid-area: products;
    background-color: white;
    padding: 1rem;
}

.auth {
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: .875rem;
    position: relative;
}

.percent {
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: .5rem;
    padding-bottom: .25rem;
    font-weight: bold;
    color: var(--white-color);
    font-size: 1.75rem;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3rem;
    background-color: var(--accent-alt-color);
    border-bottom-left-radius: 5%;
    border-top-left-radius: 75%;
}

.auth a {
    color: var(--accent-alt-color);
    text-decoration: none;
    font-weight: bold;
}

.auth a:hover {
    text-decoration: underline;
}

.detailSide {
    grid-area: detail;
}

.detail {
    background-color: white;
    padding: 1rem;
    align-self: start;
    display: flex;
    flex-direction: column;
    position: relative;
}

.detail.mute {
    background-color: #ececec;
}

.detail h2 {
    margin: 0;
}

.detail > button:not(:last-child) {
    margin-bottom: .5rem;
}

.product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    grid-template-areas:
        "image name name"
        "image count price";
    align-items: start;
}

.product:not(:last-child) {
    margin-bottom: 1rem;
}

.name {
    grid-area: name;
    align-self: start;
    justify-self: start;
}

.name small {
    font-style: italic;
    color: var(--dark-color);
    font-size: .875rem;
}

.product img {
    height: 6rem;
    width: 6rem;
    object-fit: cover;
    grid-area: image;
    align-self: center;
}

.count {
    grid-area: count;
}

.count span {
    margin: 0 .75rem;
}

.dec, .inc {
    font-size: 1.25rem;
    border: 1px solid var(--grey-color);
    background-color: rgb(245, 245, 245);
    outline: none;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color .25s ease-in;
}

.dec:hover, .inc:hover {
    background-color: var(--grey-color);
}

.count, .priceBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.25rem;
}

.priceBlock {
    flex-direction: column;
    justify-self: right;
    justify-content: right;
    grid-area: price;
}

.price {
    display: flex;
    flex-direction: column;
}

.priceSale {
    text-decoration: line-through;
    color: var(--dark-color);
    font-size: 1rem;
    align-self: flex-end;
}

/*.price::after, .priceSale::after {*/
/*    content: "₽";*/
/*}*/

.tags {
    display: flex;
    flex-direction: column;
}

.tags > .tag:not(:last-child) {
    margin-bottom: .25rem;
}

.tag {
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
}

.tagKey {
    flex: 1 0 auto;
    display: flex;
    align-items: flex-start;
    padding-right: 5px;
    white-space: nowrap;
    margin: 0;
}

.tagKey span {
    padding-right: 5px;
    text-transform: lowercase;
}

.tagKey::after {
    background-image: linear-gradient(90deg,#7f7f7f 0,#7f7f7f 25%,hsla(0,0%,100%,0) 0,hsla(0,0%,100%,0));
    flex: 1 1 auto;
    content: "";
    display: block;
    height: 16px;
    min-width: 25px;
    width: 100%;
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    box-sizing: border-box;
}

.tagValue {
    padding: 0;
    margin: 0;
    flex: 0 0 auto;
}

.tagValue::after {
    content: "₽";
}

.attrs {
    width: 100%;
    margin-right: auto;
}

.tag.tagTotal {
    margin: 1rem 0 2rem;
}

.tag.tagTotal .tagValue {
    font-size: 1.5rem;
}

.tag.tagTotal .tagKey::after {
    height: 24px;
}

.tag.tagTotal .tagKey span {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--text-color);
}

.promoBlock {
    display: grid;
    grid-template-rows: 1fr;
    gap: 0;
}

.promoBlock p {
    margin: 0;
    padding: 0;
}

.promoBlock div {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.promoBlock div input {
    width: calc(66.66% - 0.5rem);
}

.promoBlock div button {
    width: calc(33.33% - 0.5rem);
}

.hidden {
    display: none;
}

.promoText {
    text-align: center;
    width: 100%;
    font-weight: bold;
}
.Banner {
    width: 100%;
    padding: 1rem;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    align-items: center;
    background-color: var(--accent-alt-color);
    color: white;
    text-align: center;
}
.Banner>p {
    margin: 0;
    font-weight: bold;
}
.Banner span {
    transform: translateY(-1rem);
}
@media (min-width: 768px) {

    .cart {
        margin: 12rem auto 4rem;
        grid-template-columns: 5fr 2fr;
        grid-template-areas:
            "products detail";
    }

    .product {
        grid-template-columns: 1fr 5fr 2fr 2fr;
        gap: 2rem;
        grid-template-areas:
            "image name count price";
        align-items: center;
    }

    .priceBlock {
        justify-self: right;
        justify-content: right;
    }

}