.contacts {
    margin: 6rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    padding: 0 1rem;
}

.empty {
    grid-area: empty;
}

.header {
    display: flex;
    margin: 0 auto;
    flex-direction: column-reverse;
    padding: 0;
    gap: 1em;
}

.header+h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.header .phones {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header .phone {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    margin: 1rem 0;
}

.header .wa {
    display: inline-flex;
    background-color: var(--text-color);
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    padding: .5rem 1.25rem;
    color: var(--white-color);
    font-weight: bold;
    font-size: 1rem;
}

.header .block {
    grid-area: title;
}

.header .block h2, .header .empty h2 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.selectBox {
    max-width: 200px;
    margin: 0;

    margin-top: 1em;
}

.header .block p {
    margin: 0;
    padding: 0;
    max-width: 25rem;
    font-size: .875rem;
    width: 100%;
}

.Title {
    width: 100%;
    font-weight: bold;
    text-align: center;
    border: 4px solid var(--light-blue-color);
    padding: 1em;
    margin-bottom: 4em;
}
@media (min-width: 768px) {
    
    .header {
        display: grid;
        margin: 0 auto;
        grid-template-columns: 50% auto;
        gap: .5em;
        grid-template-areas: "empty title";
        padding: 0;
    }
    .contacts {
        margin: 8rem auto 4rem;
        padding: 0 2rem;
    }

    .header .phone {
        font-size: 1.25rem;
    }

    .header .block p {
        font-size: 1rem;
    }
}