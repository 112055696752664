.profile {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    gap: 1rem;
    grid-template-areas:
        "sidebar"
        "content";
}

.sidebar {
    grid-area: sidebar;
    justify-self: center;
}

.name {
    text-align: right;
}

.name h1 {
    margin: 0;
    font-size: 2.25rem;
}

.name .wallets {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.name .wallets a {
    cursor: pointer;
}

.name .wallets img {
    height: 2.5rem;
    width: auto;
}

.name .wallets > a:not(:last-child) {
    margin-right: .5rem;
}

.name .bonuses {
    display: flex;
    justify-content: flex-end;
    margin-top: .5rem;
    font-weight: bold;
}

.name .bonuses div:last-child {
    border-left: 1px solid rgb(230, 230, 230);
    padding-left: .5rem;
    margin-left: .5rem;
}

.name .bonuses > div {
    display: flex;
    flex-direction: column;
}

.links {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.links > a {
    display: inline-block;
    text-transform: lowercase;
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-color);
    width: auto;
}

.links > a:hover, a.active {
    box-shadow: inset 0 -2px var(--text-color);
}

.links > a:not(:last-child) {
    margin-bottom: .5rem;
}

.content {
    grid-area: content;
}

.content h2 {
    font-size: 1.75rem;
    line-height: 1;
    margin: 0;
    padding: 1rem 2rem;
}

.form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
}

.form > *:not(:last-child) {
    margin-bottom: 2rem;
}

.form .sex {
    display: flex;
    justify-content: space-between;
}

.groupTag {
    display: flex;
    align-items: center;
    height: 2.5rem;
}

input[type=radio].checkbox {
    z-index: -1;
    opacity: 0;
    padding: 0;
    margin: 0;
}

input[type=radio].checkbox + label {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    margin-left: -.75rem;
    display: flex;
    align-items: center;
}

label {
    font-weight: bold;
}

input[type=radio].checkbox + label:hover {
    color: var(--accent-alt-color);
    font-size: 1rem;
}

input[type=radio].checkbox + label::before {
    transition: all 0.3s;
    content: "";
    display: inline-flex;
    width: 1em;
    height: 1em;
    border: 1px solid #adb5bd;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-right: .5rem;
}

input[type=radio].checkbox:checked + label::before {
    transition: all 0.3s;
    border-color: var(--accent-alt-color);
    background-color: var(--accent-alt-color);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    box-shadow: 1px 1px 3px 0 var(--accent-alt-color);
}

.items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    gap: 1rem;
    padding: 0 1rem;
}

.item {
    grid-area: item;
    margin: 0;
}

.item img {
    width: 100%;
    height: auto;
}

.items .item {

}

.order {
    display: flex;
    flex-direction: column;
    gap: 1em;
    border-top: 1px solid var(--text-color);
    padding: 1em 0;
    margin: 1em 0;
    cursor: pointer;
}
.orderMore {
    display: flex;
    flex-direction: column;
    gap: 1em;
    border-top: 1px solid var(--text-color);
    padding: 1em 0;
    margin: 1em 0;
}
.orderHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
}
.saveProfile {
    color: var(--accent-alt-color);
}
.orderInfo {
    font-weight: bold;
}

.orderInfo_code {
    font-size: 1.5em;
    text-transform: uppercase;
}
.orderInfo > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
}
.orderPayed, .orderNotPayed {
    position: relative;
    padding-right: 1.5em;
}
.orderPayed::after, .orderNotPayed::after {
    content: "";
    width: .8em;
    height: .8em;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 100%;
}
.orderPayed::after {
    background-color: green;
}
.orderNotPayed::after {
    background-color: red;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.orderList {
    display: flex;
    flex-direction: column;
    gap: .5em;
}


.orderOtherInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .6em;
    font-size: .9em;
}
.orderOtherInfo>span:nth-child(2n+1) {
    font-weight: 600;
}
.orderOtherInfo>span:nth-child(2n) {
    text-align: end;
}

.deliveryPrice, .price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
}
.price {
    padding-bottom: .5em;
    border-bottom: 1px solid var(--text-color);
}
.orderBack {
    text-decoration: none;
    font-size: 2em;
    color: var(--text-color);
}



.productCard {
    display: grid;
    grid-template-columns: 200px auto;
    width: 100%;
    gap: 1em;
}
.productCardImg {
    width: 100%;
    height: auto;
}
.productCardImg>img {
    width: 100%;
    height: auto;
}
.productCardInfo {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.productCardInfo_price {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;
}
.productCardInfo_price>span:first-child {
    font-size: .9em;
    text-decoration: line-through;
}
.productCardInfo_price>span:last-child {
    font-weight: bold;
    font-size: 1em;
    text-decoration: none;
}
@media (min-width: 768px) {
    .items {
        gap: 2rem;
        padding: 2rem 0;
    }

    .profile {
        margin: 12rem auto 4rem;
        grid-template-columns: 1fr 1.5fr;
        gap: 4rem;
        grid-template-areas:
            "sidebar content";
    }

    .name .wallets > a:not(:last-child) {
        margin-right: 0;
        margin-bottom: .5rem;
    }

    .name .wallets {
        margin-top: 2rem;
        flex-direction: column;
    }

    .sidebar {
        justify-self: end;
    }

    .content h2 {
        font-size: 5rem;
        padding: 0;
    }

    .form {
        margin-top: 4rem;
        max-width: 30rem;
        padding: 0;
    }

}