.event {
    margin: 8rem auto 4rem;
    max-width: var(--max-width);
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 50% auto;
    gap: 0;
    grid-template-areas: "empty title";
    padding: 0;
}

.header h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

.promos {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    gap: 4rem;
    margin: 0 auto;
    padding: 0 1rem;
}

.promos a {
    color: var(--text-color);
    text-decoration: none;
}

.promo {
    position: relative;
}

.content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    gap: 1em;
}

.content p, .content h2 {
    margin: 0;
    padding: 0;
}
.content time {
    width: 50%;
    font-size: .9em;
}
.content h2 {
    width: 75%;
}

.content h2 {
    text-align: right;
    line-height: 1;
}

.promo img {
    width: 100%;
    height: auto;
}

.singlePromoTitle {
    font-size: 1.5rem !important;
}

.singlePromo {
    display: grid;
    grid-template-areas:
            "image"
            "dates"
            "content";
    grid-template-columns: 100%;
    place-content: center;
    gap: 1rem 0;
}

.singlePromoImage {
    grid-area: image;
    width: 100%;
    height: auto;

}

.singlePromoDates {
    grid-area: dates;
    place-self: center;
    font-weight: bold;
    font-size: 1.2rem;
}

.singlePromoContent {
    grid-area: content;
    padding: 0 2rem;
}
.banner {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 33.3vh;
    background-size: cover;
    background-position: bottom center;
}
@media (min-width: 768px) {
    .event {
        margin: 12rem auto 4rem;
        margin-top: 5rem;
    }

    .promos {
        grid-template-columns: repeat(2, 1fr);
        margin: 0 auto 4rem;
        padding: 0;
    }

    .content {
        padding: 0 2rem;
    }

   
    .singlePromoTitle {
        font-size: 3rem !important;
    }

    .singlePromo {
        grid-template-areas:
            "image image image image dates dates"
            "empty empty empty content content content";
        grid-template-columns: repeat(6, 1fr);
    }

    .singlePromoContent {
        padding: 2rem 2rem 2rem 0
    }
}