.itemsGroup {
    width: 100%;
}

.empty {
    grid-area: empty;
}

.header {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 33% auto auto;
    gap: 0;
    grid-template-areas: "empty title arrows";
    padding: 0;
    max-width: 100%;
}

.header h2 {
    font-size: 1.5rem;
    font-weight: bold;
    grid-area: title;
}

.arrows {
    grid-area: arrows;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.arrowNext, .arrowPrev {
    outline: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    -webkit-appearance: button;
}

.arrowPrev {
    margin-right: 1rem;
}

.arrowNext img, .arrowPrev img {
    width: 20px;
    height: auto;
}

.arrowNext img {
    transform: rotate(180deg);
}

.items {
    display: grid;
    grid-template-columns: var(--perPage);
    gap: 2rem;
}

@media (min-width: 768px) {

    .header {
        display: grid;
        margin: 0 auto;
        grid-template-columns: 50% auto 100px;
        gap: 0;
        grid-template-areas: "empty title arrows";
        padding: 0;
    }

    .header h2 {
        font-size: 2rem;
    }

}