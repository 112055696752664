.aside, .search {
    z-index: 999;
    background: var(--white-color);
    width: 25rem;
    position: fixed;
    top: 4rem;
    bottom: 0;
    opacity: 0;
    transition: transform 1s, opacity 1s;
    padding: 5rem 2rem;
}

.aside {
    left: 0;
    transform: translateX(-25rem);
}

.search {
    width: 100%;
    right: 0;
    transform: translateX(100vh);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 10fr;
    gap: 1rem;
    grid-template-areas:
        "search"
        "results";
    z-index: -999;
}

.empty {
    grid-area: empty;
    display: none;
}

.recs {
    grid-area: recs;
    overflow-y: auto;
    padding: 0 1rem;
    display: none;
}

.search .results {
    list-style: none;
    padding: 0;
    grid-area: results;
    margin: 0;
    overflow-y: auto;
}

.search .results > li:not(:last-child) {
    margin-bottom: .75rem;
}

.search .results a {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 600;
    font-size: 1.125rem;
}

.search .results a:hover {
    text-decoration: underline;
}

.input {
    width: 100%;
    min-height: 65px;
    max-height: 100%;
    padding: 0;
    border: 0;
    font-size: 60px;
    line-height: 65px;
    font-weight: 500;
    letter-spacing: -.2px;
    overflow: hidden;
    resize: none;
    background: 0 0;
    grid-area: search;
}

.input:focus {
    outline: none;
}

.aside.active {
    transform: translateX(0);
    opacity: 1;
    z-index: 1002;
}
.search.active {
    transform: translateX(0);
    opacity: 1;
    z-index: 1001;
}

.links {
    list-style: none;
    padding: 0;
}

.links > li a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.75rem;
    font-weight: bold;
}

.links > li:not(:last-child) {
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    .search {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 8fr;
        gap: 1rem 4rem;
        grid-template-areas:
            "empty search"
            "recs results";
    }

    .empty {
        display: block;
    }

    .recs {
        display: block;
    }
}