.main {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    /* min-height: 100%; */
    flex-direction: column;
    z-index: 999;
}

.inner {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.h2 {
    font-size: 2rem;
    font-weight: bold;
    grid-area: title;
}

@media (min-width: 768px) {
    .inner {
        padding: 2rem;
    }
}