.items {
    grid-area: items;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.search {
    grid-template-columns: 1fr;
}

@media (min-width: 768px) {
    .items {
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }

    .search {
        grid-template-columns: repeat(2, 1fr);
    }
}