.btn {
    display: flex;
    padding: .25rem .25rem;
    color: var(--text-color);
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    /*border: 1px solid var(--text-color);*/
}

.btn span {
    margin-left: .5rem;
}

.btn img {
    height: 1rem!important;
    width: auto;
}