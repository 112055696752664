.btn {
    background-color: transparent;
    outline: none;
    border-radius: 10px;
    border: 1px solid transparent;
    padding: .25rem 1.25rem;
    font-size: .875rem;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
    -webkit-transition: .3s;
}

.accent {
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
}

.btn:hover {
    color: var(--accent-color);
    background: transparent;
}