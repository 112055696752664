.stories {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stories > .storyPreview:not(:last-child) {
    margin-right: 1rem;
}

.storyPreview {
    width: 4rem;
    height: 4rem;
    border: 2px solid var(--accent-color);
    border-radius: 50%;
    padding: .2rem;
    cursor: pointer;
    transition: .25s border ease-in;
    margin: 0;
}

.storyPreview:hover {
    border-style: dashed;
}

.storyPreview img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.wrapper {
    position: fixed;
    display: none;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(28, 28, 28);
    justify-content: center;
    align-items: center;
}

.wrapperActive {
    display: flex;
}

.storiesPlace {
    height: 90vh;
    width: 100%;
    border: 1px solid red;
    display: grid;
    gap: 2rem;
    grid: [story] 1fr / [story] 1fr;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    inline-size: 100vw;
    block-size: 100vh;
    overscroll-behavior: contain;
    touch-action: pan-x;
}

.story {
    opacity: .5;
    transform: scale(.75);
    transition: all .2s ease-in;
    cursor: pointer;
    border: 1px solid red;
    height: 100%;
    width: auto;
    grid-area: story;
    background-size: cover;
}

.storyActive {
    transform: scale(1);
    opacity: 1;
    border: 1px solid green;
}

.story > video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.story > img {
    position: absolute;
    max-width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}